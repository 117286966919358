<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1200px;" v-if="!isLoading && !isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231212/pc_bg.jpg" alt="">
            <img src="../../assets/imgs/activity/20231212/pc_title.png" alt="" class="topTitle">
            <div class="content">
                <div class="top1Box" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20231212/icon_wing.gif" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20231212/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].name }}</div>
                    <div class="numBox d-flex a-center j-center">
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[0].num }}</div>
                        </div>
                        <div class="muiscBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                            <div class="number">x {{ rankList[0].giftCount }}</div>
                        </div>
                    </div>
                </div>
                <div class="d-flex a-center j-center">
                    <div class="top2Box" v-if="rankList[1]">
                        <img src="../../assets/imgs/activity/20231212/pc_TOP2.png" alt="" class="topBg">
                        <img :src="rankList[1].adUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[1].name }}</div>
                        <div class="numBox d-flex a-center j-center">
                            <div class="hotBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                                <div class="hotNumber">{{ rankList[1].num }}</div>
                            </div>
                            <div class="muiscBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                                <div class="number">x {{ rankList[1].giftCount }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="top3Box" v-if="rankList[2]">
                        <img src="../../assets/imgs/activity/20231212/pc_TOP3.png" alt="" class="topBg">
                        <img :src="rankList[2].adUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[2].name }}</div>
                        <div class="numBox d-flex a-center j-center">
                            <div class="hotBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                                <div class="hotNumber">{{ rankList[2].num }}</div>
                            </div>
                            <div class="muiscBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                                <div class="number">x {{ rankList[2].giftCount }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.adUrl" alt="" class="roomImg">
                            <div class="roomName d-overflow flex-1">{{ item.name }}</div>
                            <div class="numBox d-flex a-center j-center">
                                <div class="hotBox d-flex a-center j-center">
                                    <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                                    <div class="hotNumber">{{ item.num }}</div>
                                </div>
                                <div class="muiscBox d-flex a-center j-center">
                                    <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                                    <div class="number">x {{ item.giftCount }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231212/pc_ruleTitle.png" alt="" class="ruleTitle">
                    <div class="ruleContent">
                        <p>
                            1、活动时间为2023年12月11日0点至12月13日24点<br />
                            2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）特殊礼物：<span
                                class="yel">“爱悦之音”</span>可获得*20倍热度值<br />
                            3、前十名奖励中的52钻礼物为限定礼物<span class="yel">“兔音狂欢”</span>，无其他渠道获取，活动结束后24小时内发放到厅管背包。<br />
                            4、定制系列为定制厅头像框，活动结束联系运营进行定制。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <img src="../../assets/imgs/activity/20231212/pc_rewardTitle.png" alt="" class="rewardTitle">
                    <div class="rewardContent">
                        <p>
                            <span class="title">第一名</span><br />
                            52钻石限定礼物*1000/厅定制头像框（500天）/一个月厅推荐位/全套虚拟装扮*100天<br />
                            <span class="title">第二名</span><br />
                            52钻石限定礼物*600/厅定制头像框（400天）/15天厅推荐位/全套虚拟装扮*50天<br />
                            <span class="title">第三名</span><br />
                            52钻石礼物*300/厅定制头像框（300天）/7天厅推荐位/全套虚拟装扮*30天<br />
                            <span class="title">第4~6名</span><br />
                            52钻石限定礼物*100/全套虚拟装扮*15天<br />
                            <span class="title">第7~10名</span><br />
                            52钻石限定礼物*50/全套虚拟装扮*15天<br />
                        </p>
                        <p class="desc">
                            <span class="title">特别奖</span><br />
                            收到<span class="yel">“爱悦之音”</span>最多的厅，52钻礼物*100/全套虚拟装扮*30天
                        </p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231212/h5_bg.jpg" alt="">
            <img src="../../assets/imgs/activity/20231212/h5_title.png" alt="" class="topTitle">
            <div class="content">
                <div class="top1Box topBox" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20231212/icon_wing.gif" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20231212/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].name }}</div>
                    <div class="numBox d-flex a-center j-center">
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[0].num }}</div>
                        </div>
                        <div class="muiscBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                            <div class="number">x {{ rankList[0].giftCount }}</div>
                        </div>
                    </div>
                </div>
                <div class="top2Box topBox" v-if="rankList[1]">
                    <img src="../../assets/imgs/activity/20231212/pc_TOP2.png" alt="" class="topBg">
                    <img :src="rankList[1].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[1].name }}</div>
                    <div class="numBox d-flex a-center j-center">
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[1].num }}</div>
                        </div>
                        <div class="muiscBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                            <div class="number">x {{ rankList[1].giftCount }}</div>
                        </div>
                    </div>
                </div>
                <div class="top3Box topBox" v-if="rankList[2]">
                    <img src="../../assets/imgs/activity/20231212/pc_TOP3.png" alt="" class="topBg">
                    <img :src="rankList[2].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[2].name }}</div>
                    <div class="numBox d-flex a-center j-center">
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[2].num }}</div>
                        </div>
                        <div class="muiscBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                            <div class="number">x {{ rankList[2].giftCount }}</div>
                        </div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.adUrl" alt="" class="roomImg">
                            <div class="right">
                                <div class="roomName d-overflow">{{ item.name }}</div>
                                <div class="numBox d-flex a-center">
                                    <div class="hotBox d-flex a-center j-center">
                                        <img src="../../assets/imgs/activity/20231212/icon_hot.png" alt="" class="iconHot">
                                        <div class="hotNumber">{{ item.num }}</div>
                                    </div>
                                    <div class="muiscBox d-flex a-center j-center">
                                        <img src="../../assets/imgs/activity/20231212/icon_muisc.png" alt="" class="icon">
                                        <div class="number">x {{ item.giftCount }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231212/h5_ruleTitle.png" alt="" class="ruleTitle">
                    <div class="ruleContent">
                        <p>
                            1、活动时间为2023年12月11日0点至12月13日24点<br />
                            2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）特殊礼物：<span
                                class="yel">“爱悦之音”</span>可获得*20倍热度值<br />
                            3、前十名奖励中的52钻礼物为限定礼物<span class="yel">“兔音狂欢”</span>，无其他渠道获取，活动结束后24小时内发放到厅管背包。<br />
                            4、定制系列为定制厅头像框，活动结束联系运营进行定制。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <img src="../../assets/imgs/activity/20231212/h5_rewardTitle.png" alt="" class="rewardTitle">
                    <div class="rewardContent">
                        <p>
                            <span class="title">第一名</span><br />
                            52钻石限定礼物*1000/厅定制头像框（500天）/一个月厅推荐位/全套虚拟装扮*100天<br />
                            <span class="title">第二名</span><br />
                            52钻石限定礼物*600/厅定制头像框（400天）/15天厅推荐位/全套虚拟装扮*50天<br />
                            <span class="title">第三名</span><br />
                            52钻石礼物*300/厅定制头像框（300天）/7天厅推荐位/全套虚拟装扮*30天<br />
                            <span class="title">第4~6名</span><br />
                            52钻石限定礼物*100/全套虚拟装扮*15天<br />
                            <span class="title">第7~10名</span><br />
                            52钻石限定礼物*50/全套虚拟装扮*15天<br />
                        </p>
                        <p class="desc">
                            <span class="title">特别奖</span><br />
                            收到<span class="yel">“爱悦之音”</span>最多的厅，52钻礼物*100/全套虚拟装扮*30天
                        </p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
            <img class="bgBottom" src="../../assets/imgs/activity/20231212/h5_bottom.png" alt="">
        </div>

    </div>
</template>

<script>
import {
    getRankRoomListForDouble12
} from "@/util/api/api.js"
export default {
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            rankList: [],// 厅流水排行榜列表
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._getRankRoomListForDouble12()
        // this.isLoading = false
    },
    methods: {
        // 获取厅流水排行榜
        async _getRankRoomListForDouble12() {
            try {
                let res = await getRankRoomListForDouble12()
                if (res.resultCode == '000000') {
                    console.log(res)
                    if (res.data && res.data.list) {
                        this.rankList = res.data.list
                        this.isLoading = false
                    }
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #000000;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .topTitle {
            position: relative;
            margin-top: 43px;
            margin-left: -45px;
            display: block;
            height: 430px;
            width: 1238px;
            z-index: 1;
        }

        .content {
            position: relative;
            z-index: 1;

            .top1Box {
                position: relative;
                margin: 64px auto 0;
                width: 358px;
                padding: 1px;
                box-sizing: border-box;

                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 664px;
                    transform: translate(-50%, -67%);
                }

                .topBg {
                    position: absolute;
                    display: block;
                    width: 358px;
                    z-index: 1;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 218px;
                    width: 218px;
                    background: #ccc;
                    border-radius: 16px;
                    margin: 10px auto 0;
                }

                .roomName {
                    position: relative;
                    width: 190px;
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #4C3232;
                    text-align: center;
                    margin: 23px auto 0;
                    z-index: 1;
                }

                .numBox {
                    width: 150%;
                    position: relative;
                    left: -25%;

                    .hotBox {
                        position: relative;
                        margin-top: 34px;

                        .iconHot {
                            display: block;
                            height: 48px;
                            width: 42px;
                            margin-right: 11px;
                        }

                        .hotNumber {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }

                    .muiscBox {
                        position: relative;
                        margin-top: 34px;
                        margin-left: 32px;

                        .icon {
                            display: block;
                            height: 48px;
                            width: 48px;
                            margin-right: 8px;
                        }

                        .number {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .top2Box,
            .top3Box {
                position: relative;
                margin-top: 48px;
                width: 358px;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 358px;
                    z-index: 1;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 218px;
                    width: 218px;
                    background: #ccc;
                    border-radius: 16px;
                    margin: 10px auto 0;
                }

                .roomName {
                    position: relative;
                    width: 190px;
                    font-size: 18px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #4C3232;
                    text-align: center;
                    margin: 23px auto 0;
                    z-index: 1;
                }

                .numBox {
                    width: 150%;
                    position: relative;
                    left: -25%;

                    .hotBox {
                        position: relative;
                        margin-top: 34px;

                        .iconHot {
                            display: block;
                            height: 48px;
                            width: 42px;
                            margin-right: 11px;
                        }

                        .hotNumber {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }

                    .muiscBox {
                        position: relative;
                        margin-top: 34px;
                        margin-left: 32px;

                        .icon {
                            display: block;
                            height: 48px;
                            width: 48px;
                            margin-right: 8px;
                        }

                        .number {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .top2Box {
                margin-right: 135px;
            }

            .list {
                width: 1250px;
                height: 980px;
                overflow-y: scroll;
                margin: 72px auto 0;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 12px;
                    background: rgba(255, 255, 255, 0.4);
                    // border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 12px;
                    background: #FFCB9A;
                    // border-radius: 8px;
                }

                .item {
                    width: 1200px;
                    height: 140px;

                    &.itemBg {
                        background: rgba(255, 255, 255, 0.2);
                    }

                    .sortNum {
                        width: 64px;
                        height: 64px;
                        background: #FFCB9A;
                        border-radius: 48px;
                        line-height: 64px;
                        text-align: center;
                        font-size: 40px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #752F25;
                        margin-left: 27px;
                    }

                    .roomImg {
                        display: block;
                        width: 100px;
                        height: 100px;
                        background: #ccc;
                        border-radius: 16px;
                        margin-left: 32px;
                    }

                    .roomName {
                        width: 332px;
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin: 0 32px;
                    }

                    .numBox {
                        padding-right: 40px;

                        .hotBox {
                            position: relative;

                            .iconHot {
                                display: block;
                                height: 48px;
                                width: 42px;
                                margin-right: 11px;
                            }

                            .hotNumber {
                                font-size: 32px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                            }
                        }

                        .muiscBox {
                            position: relative;
                            margin-left: 32px;

                            .icon {
                                display: block;
                                height: 48px;
                                width: 48px;
                                margin-right: 8px;
                            }

                            .number {
                                font-size: 32px;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .ruleBox {
                padding-top: 128px;

                .ruleTitle {
                    display: block;
                    height: 95px;
                    width: 406px;
                    margin: 0 auto;
                }

                .ruleContent {
                    margin: 23px auto 0;
                    height: 449px;
                    width: 1243px;
                    background: url('../../assets/imgs/activity/20231212/pc_ruleBg.png')no-repeat center/cover;
                    font-size: 32px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 85px 86px 0 90px;
                    box-sizing: border-box;

                    .yel {
                        color: #FFDA00;
                    }
                }
            }

            .rewardBox {
                padding-top: 96px;

                .rewardTitle {
                    display: block;
                    height: 95px;
                    width: 406px;
                    margin: 0 auto;
                }

                .rewardContent {
                    margin: 23px auto 0;
                    height: 876px;
                    width: 1241px;
                    background: url('../../assets/imgs/activity/20231212/pc_rewardBg.png')no-repeat center/cover;
                    font-size: 32px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 75px 79px 0 95px;
                    box-sizing: border-box;

                    p {
                        .title {
                            color: #FFE3B4;
                        }

                        .yel {
                            color: #FFDA00;
                        }
                    }

                    .desc {
                        margin-top: 46px;
                    }
                }
            }

            .descBox {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 71px;
                padding-bottom: 106px;
            }
        }



        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;
        background: #70282B;

        .bg_activity {
            width: 100vw;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        .topTitle {
            position: relative;
            margin: 5.333vw auto;
            display: block;
            height: 53.067vw;
            width: 92.267vw;
            // z-index: 1;
        }

        .content {
            position: relative;

            .topBox {
                position: relative;
                margin: 0 auto;
                width: 61.6vw;
                padding: 1px;
                box-sizing: border-box;
                margin-bottom: 8vw;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 61.6vw;
                    z-index: 1;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    width: 37.6vw;
                    height: 37.6vw;
                    background: #ccc;
                    border-radius: 2.133vw;
                    margin: 1.6vw auto 0;
                }

                .roomName {
                    position: relative;
                    width: 33.6vw;
                    font-size: 3.2vw;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    color: #4C3232;
                    text-align: center;
                    margin: 4vw auto 0;
                    z-index: 1;
                }

                .numBox {
                    position: relative;
                    z-index: 1;

                    .hotBox {
                        position: relative;
                        margin-top: 6.4vw;

                        .iconHot {
                            display: block;
                            height: 6.4vw;
                            width: 5.6vw;
                            margin-right: 2.133vw;
                        }

                        .hotNumber {
                            font-size: 4.267vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }

                    .muiscBox {
                        position: relative;
                        margin-top: 6.4vw;
                        margin-left: 4vw;

                        .icon {
                            display: block;
                            height: 6.4vw;
                            width: 6.4vw;
                            margin-right: 1.6vw;
                        }

                        .number {
                            font-size: 4.267vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .top1Box {
                padding-top: 9.333vw;

                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 100vw;
                    transform: translate(-50%, -67%);
                }
            }

            .list {
                height: 149.333vw;
                overflow-y: scroll;
                margin: 0 5.333vw;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 0.533vw;
                    background: rgba(255, 255, 255, 0.4);
                    // border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 0.533vw;
                    background: #FFCB9A;
                    // border-radius: 8px;
                }

                .item {
                    width: 85.333vw;
                    height: 21.333vw;

                    &.itemBg {
                        background: rgba(255, 255, 255, 0.2);
                    }

                    .sortNum {
                        width: 8.533vw;
                        height: 8.533vw;
                        line-height: 8.533vw;
                        text-align: center;
                        background: #FFCB9A;
                        border-radius: 50%;
                        font-size: 5.333vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #752F25;
                        margin-left: 3.733vw;
                    }

                    .roomImg {
                        display: block;
                        width: 17.067vw;
                        height: 17.067vw;
                        background: #ccc;
                        border-radius: 2.133vw;
                        margin-left: 5.867vw;
                        margin-right: 3.2vw;
                    }

                    .roomName {
                        width: 44vw;
                        font-size: 3.2vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }

                    .numBox {
                        position: relative;
                        z-index: 1;

                        .hotBox {
                            margin-top: 2.4vw;

                            .iconHot {
                                height: 4.267vw;
                                width: 3.733vw;
                                margin-right: 1.067vw;
                            }

                            .hotNumber {
                                font-size: 3.2vw;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                            }
                        }

                        .muiscBox {
                            position: relative;
                            margin-left: 4.267vw;
                            margin-top: 2.4vw;

                            .icon {
                                height: 4.267vw;
                                width: 4.267vw;
                                margin-right: 1.067vw;
                            }

                            .number {
                                font-size: 3.2vw;
                                font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .ruleBox {
                padding-top: 12.8vw;

                .ruleTitle {
                    display: block;
                    height: 12.533vw;
                    width: 53.6vw;
                    margin: 0 auto;
                }

                .ruleContent {
                    margin: 4.267vw auto 0;
                    height: 81.6vw;
                    width: 91.733vw;
                    background: url('../../assets/imgs/activity/20231212/h5_ruleBg.png')no-repeat center/cover;
                    font-size: 4.267vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 8.267vw 5.867vw 0 6.933vw;
                    box-sizing: border-box;

                    .yel {
                        color: #FFDA00;
                    }
                }
            }

            .rewardBox {
                padding-top: 12.8vw;

                .rewardTitle {
                    display: block;
                    height: 12.533vw;
                    width: 53.6vw;
                    margin: 0 auto;
                }

                .rewardContent {
                    margin: 6.4vw auto 0;
                    height: 129.067vw;
                    width: 91.733vw;
                    background: url('../../assets/imgs/activity/20231212/h5_rewardBg.png')no-repeat center/cover;
                    font-size: 4.267vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    padding: 8.267vw 5.867vw 0 6.933vw;
                    box-sizing: border-box;

                    p {
                        .title {
                            color: #FFE3B4;
                        }

                        .yel {
                            color: #FFDA00;
                        }
                    }

                    .desc {
                        margin-top: 6.133vw;
                    }
                }
            }

            .descBox {
                width: 86.933vw;
                font-size: 3.2vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 7.2vw;
                padding-bottom: 17.067vw;
            }
        }

        .bgBottom {
            width: 100%;
            display: block;
            position: absolute;
            bottom: 0;
        }
    }
}
</style>
